import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

import {
    ConfirmDialogAction,
    DeleteDialogAction,
    InfoDialogAction,
    PromptDialogAction,
} from '../../../../models/simple-dialog.model';
import { BasePopupComponent } from '../base-popup/base-popup.component';

@Component({
    selector: 'app-confirm-info-popup',
    templateUrl: './confirm-info-popup.component.html',
    styleUrls: ['./confirm-info-popup.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmInfoPopupComponent<TResult extends string | boolean>
    extends BasePopupComponent<ConfirmInfoPopupComponent<TResult>>
    implements OnDestroy, AfterViewInit, OnInit
{
    public options: ConfirmDialogAction | InfoDialogAction | PromptDialogAction | DeleteDialogAction;

    public onClose = new EventEmitter<TResult | null>();

    public prompt: string = null;
    protected validationErrors: Array<string> | undefined;
    protected validationTrigger$ = new Subject<void>();

    @ViewChild('promptInput') public input: ElementRef<HTMLInputElement>;

    public constructor(elementRef: ElementRef) {
        super(elementRef);
        this.popupOptions.padding = 0;
        this.validationTrigger$
            .pipe(
                tap(() => (this.validationErrors = undefined)),
                debounceTime(300),
                tap(() => this.validateInput())
            )
            .subscribe();
    }

    @HostListener('document:keydown.enter')
    public onEnterPressed(): void {
        if (this.options.action === 'confirm') {
            this.close(true);
        }
    }

    public ngOnDestroy(): void {
        this.emitClose();
    }

    public ngAfterViewInit(): void {
        if (this.options.action === 'prompt') {
            this.input.nativeElement.focus();
        } else {
            this.validationTrigger$.next();
        }
    }

    public onInputChange(enterKeyPressed = false): void {
        if (this.options.action === 'prompt') {
            if (enterKeyPressed) {
                if (this.validationErrors?.length === 0 && this.prompt?.length > 0) {
                    this.close(true);
                }
            }
            this.validationTrigger$.next();
        }
    }

    public close(isConfirmed: boolean = null, $event?: MouseEvent): void {
        $event?.stopImmediatePropagation();
        if (this.options.action === 'prompt') {
            this.emitClose((isConfirmed ? this.prompt : null) as TResult | null);
        } else {
            this.emitClose(isConfirmed as TResult);
        }
        this.__DOM_COMPONENT.remove();
    }

    protected asConfirmAction(
        options: ConfirmDialogAction | InfoDialogAction | PromptDialogAction | DeleteDialogAction
    ): ConfirmDialogAction {
        return options as ConfirmDialogAction;
    }

    private validateInput(): void {
        this.validationErrors = [];

        if (this.options.action === 'prompt' && this.prompt) {
            const config = this.options?.config;
            if (config?.minLength && this.prompt.trim().length < config.minLength) {
                this.validationErrors.push('MIN_LENGTH_ERROR');
            } else if (config?.maxLength && this.prompt.trim().length > config.maxLength) {
                this.validationErrors.push('MAX_LENGTH_ERROR');
            }
        }
    }

    private emitClose(onCloseData: TResult = null): void {
        this.onClose.emit(onCloseData);
        this.onClose.complete();
    }
}
