import { Component, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ILocatable, IOrdinal, ITenantConfig } from 'shared';

import { ViewChildAsSubject } from '../../../../decorators/as-subject.decorators';
import { MapService } from '../../../services/map.service';
import { MapLayerController } from '../../map/map/layer-controllers/base-controllers/map-layer-controller';
import { IndoorMapMapLayerController } from '../../map/map/layer-controllers/indoor-map.map-layer-controller';
import { MapComponent } from '../../map/map/map.component';

export interface IPOILike extends ILocatable {
    description?: string;
    categoryId?: string;
}

@Component({
    selector: 'app-entity-details-map',
    templateUrl: './entity-details-map.component.html',
    styleUrl: './entity-details-map.component.scss',
})
export class EntityDetailsMapComponent {
    protected _poi?: Partial<IPOILike>;

    protected mapOrdinal?: Observable<IOrdinal>;
    protected mapConfig?: Observable<ITenantConfig>;

    private indoorMapController = new IndoorMapMapLayerController();
    protected layerControllers: Array<MapLayerController> = [this.indoorMapController];
    @ViewChildAsSubject()
    private map$ = new BehaviorSubject<MapComponent | undefined>(undefined);

    @Input({ required: true })
    public set poi(poi: Partial<IPOILike>) {
        this._poi = poi;
        if (poi) {
            this.setupMap();
        }
    }

    public get poi(): Partial<IPOILike> {
        return this._poi;
    }

    @Input({ required: false })
    public set layerController(controller: MapLayerController) {
        if (!this.layerControllers.includes(controller)) {
            this.layerControllers.push(controller);
        }
    }

    constructor(private readonly mapService: MapService) {}

    private setupMap(): void {
        const location = this._poi.location;

        // Set the map location
        this.mapOrdinal = this.map$.pipe(
            filter(Boolean),
            switchMap((m) => m.state.ordinals.stream),
            map((ordinals) => ordinals.find((o) => o.id === location.floor))
        );
        // Set the indoor map data (if available)
        this.indoorMapController
            .setIMDFDataStream(this.mapService.imdfData)
            .setIMDFStylesStream(this.mapService.imdfStyles);
        // Set the map config
        this.mapConfig = this.mapService.mapConfig.pipe(
            filter(Boolean),
            map((config) => {
                config = structuredClone(config);
                config.centerCoordinates.longitude = location.longitude;
                config.centerCoordinates.latitude = location.latitude;
                config.zoomBoundaries.min = config.zoom;
                config.zoomBoundaries.max = config.zoom;
                return config;
            })
        );
    }
}
