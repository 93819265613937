import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, DestroyRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, switchMap } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import {
    AssetReplacedLogEntry,
    AssetScoredLogEntry,
    AuditLogEntry,
    AuditLogEntryKeys,
    IUser,
    IssuePatchedLogEntry,
    TaskBlockedLogEntry,
    TaskPatchedLogEntry,
    hshrink,
    vshrink,
} from 'shared';

import { AssetService } from '../../../../services/asset.service';
import { POIService } from '../../../../services/poi.service';
import { UserService } from '../../../../services/user.service';

type AuditLogEntryColor = `color-icon-${string}`;
type AuditLogEntryColorMap = Record<AuditLogEntryKeys, AuditLogEntryColor>;
type AuditLogEntryMap = Record<AuditLogEntryKeys, string>;

@Component({
    selector: 'app-audit-log-entry',
    templateUrl: './audit-log-entry.component.html',
    styleUrls: ['./audit-log-entry.component.scss'],
    animations: [vshrink(), hshrink()],
})
export class AuditLogEntryComponent implements OnInit, OnChanges {
    @Input() public entry: AuditLogEntry;
    protected timestamp: number;
    protected icon: string;
    protected iconClass: AuditLogEntryColor;
    protected title: string;
    protected titleTokens: { [token: string]: string } = {};
    protected drawerCollapsed = true;
    protected hasDrawer = false;
    protected assetScore?: string;

    public constructor(
        private readonly userService: UserService,
        private readonly poiService: POIService,
        private readonly translate: TranslateService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly datePipe: DatePipe,
        private readonly destroyRef: DestroyRef,
        protected readonly assetService: AssetService
    ) {}

    public ngOnInit(): void {
        // Retrigger moment pipes on language change
        this.translate.onLangChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            if (this.entry) {
                this.timestamp = 0;
                this.changeDetectorRef.detectChanges();
                this.timestamp = this.entry.timestamp;
                this.determineTitleTokens();
            }
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.entry.previousValue === changes.entry.currentValue) return;
        this.timestamp = this.entry ? this.entry.timestamp : null;
        this.assetScore = undefined;
        this.determineIcon();
        this.determineTitleTokens();
        this.determineTitle();
        this.determineScore();
        this.hasDrawer =
            this.entry &&
            ['AssetScored', 'AssetReplaced', 'TaskBlocked', 'TaskPatched', 'IssuePatched'].includes(this.entry.type);
    }

    protected asTaskBlockedLogEntry(entry: AuditLogEntry): TaskBlockedLogEntry {
        return entry as TaskBlockedLogEntry;
    }

    protected asPatchedLogEntry(entry: AuditLogEntry): TaskPatchedLogEntry | IssuePatchedLogEntry {
        return entry as TaskPatchedLogEntry | IssuePatchedLogEntry;
    }

    protected asAssetScoreLogEntry(entry: AuditLogEntry): AssetScoredLogEntry | AssetReplacedLogEntry {
        return entry as AssetScoredLogEntry | AssetReplacedLogEntry;
    }

    protected onHeaderClick(): void {
        if (this.hasDrawer) this.drawerCollapsed = !this.drawerCollapsed;
        else this.drawerCollapsed = true;
    }

    protected getPatchDisplayValue(key: string, current: string): Observable<string> {
        switch (key) {
            case 'subjectType':
            case 'issueType':
            case 'taskType':
                return this.translate.stream(`${key}.${current}`);
            case 'pickupAfter':
            case 'finishBefore':
                return of(
                    !isNaN(parseInt(current)) ? this.datePipe.transform(parseInt(current) * 1000, 'HH:mm') : '--'
                );
            case 'poiId':
                return this.poiService.getPOIForId(current).pipe(
                    take(1),
                    map((poi) => poi?.description),
                    switchMap((description) =>
                        description ? of(description) : this.translate.stream('errors.entities.POI')
                    )
                );
            default:
                return of(current);
        }
    }

    private determineTitle(): void {
        if (this.entry.type === 'TaskCreated' && this.entry.status === 'DONE') {
            this.title = 'comp.audit-log-entry.types.' + this.entry.type + '.titleCompletedTask';
        } else {
            this.title = 'comp.audit-log-entry.types.' + this.entry.type + '.title';
        }
    }

    private determineTitleTokens(): void {
        // actorName
        switch (this.entry.actor.type) {
            case 'PlatformUser':
                this.loadUserName(this.entry.actor.userId, (userName) => (this.titleTokens.actorName = userName));
                break;
            case 'TokenUser':
                this.loadTokenActorName(this.entry.actor.userId, (userName) => (this.titleTokens.actorName = userName));
                break;
            case 'SystemUser':
            case 'ShareLinkUser':
                const key =
                    this.entry.actor.type === 'SystemUser'
                        ? 'comp.audit-log-entry.users.system'
                        : 'comp.audit-log-entry.users.shareLink';
                this.titleTokens = { ...this.titleTokens, actorName: this.translate.instant(key) };
                break;
            default:
                break;
        }

        // assigneeName
        if (this.entry.type === 'TaskAssigned' || this.entry.type === 'TaskInspectionAssigned') {
            this.loadUserName(this.entry.assigneeId, (userName) => (this.titleTokens.assigneeName = userName));
        }

        // assetType
        if (this.entry.type === 'AssetScored' || this.entry.type === 'AssetReplaced') {
            this.assetService.getAssetType(this.entry.score.assetTypeId).then((assetType) => {
                if (assetType) {
                    this.titleTokens = {
                        ...this.titleTokens,
                        assetType: this.translate.instant(assetType.name),
                    };
                }
            });
        }
    }

    private loadUserName(userId: string, onLoad: (userName: string) => void): void {
        this.loadActorName(this.userService.getUser(userId), onLoad);
    }

    private loadTokenActorName(userId: string, onLoad: (userName: string) => void): void {
        this.loadActorName(this.userService.getTokenActor(userId), onLoad);
    }

    private loadActorName(actorLoader: Observable<IUser>, onLoad: (userName: string) => void): void {
        actorLoader
            .pipe(
                take(1),
                tap((a) => onLoad(a ? a.fullName : this.translate.instant('comp.audit-log-entry.users.unknown')))
            )
            .subscribe();
    }

    private determineIcon(): void {
        const iconMap: AuditLogEntryMap = {
            AssetCreated: 'add_box',
            PreventiveMaintenanceFinished: 'assignment_turned_in',
            AssetScored: 'check_circle',
            AssetReplaced: 'swap_horiz',
            IssueImageAdded: 'insert_photo',
            IssueCreated: 'assignment',
            IssueTaskLinked: 'link',
            IssueTaskUnlinked: 'link_off',
            IssueClosed: 'assignment_turned_in',
            IssueCancelled: 'assignment_late',
            IssueArchived: 'archive',
            IssuePatched: 'edit',
            TaskCreated: 'add_box',
            TaskAssigned: 'assignment_ind',
            TaskUnassigned: 'assignment_ind',
            TaskInspectionAssigned: 'assignment_turned_in',
            TaskInspectionUnassigned: 'assignment_turned_in',
            TaskInspectionApproved: 'check_circle',
            TaskInspectionRejected: 'do_not_disturb_on',
            TaskPickedUp: 'assignment',
            TaskPickUpUndone: 'assignment',
            TaskDropped: 'assignment_returned',
            TaskBlocked: 'assignment_late',
            TaskFinished: 'assignment_turned_in',
            TaskCancelled: 'assignment_late',
            TaskUnblocked: 'assignment_return',
            TaskResumed: 'assignment',
            TaskArchived: 'archive',
            TaskPublished: 'publish',
            TaskPatched: 'edit',
            WorkRegistered: 'assignment_turned_in',
        };

        const classMap: AuditLogEntryColorMap = {
            AssetCreated: 'color-icon-green',
            PreventiveMaintenanceFinished: 'color-icon-blue',
            AssetScored: 'color-icon-blue',
            AssetReplaced: 'color-icon-blue',
            IssueImageAdded: 'color-icon-blue',
            IssueCreated: 'color-icon-green',
            IssueTaskLinked: 'color-icon-blue',
            IssueTaskUnlinked: 'color-icon-blue',
            IssueClosed: 'color-icon-green',
            IssueCancelled: 'color-icon-red',
            IssueArchived: 'color-icon-blue',
            IssuePatched: 'color-icon-orange',
            TaskCreated: 'color-icon-green',
            TaskAssigned: 'color-icon-blue',
            TaskUnassigned: 'color-icon-orange',
            TaskInspectionAssigned: 'color-icon-blue',
            TaskInspectionUnassigned: 'color-icon-orange',
            TaskInspectionApproved: 'color-icon-green',
            TaskInspectionRejected: 'color-icon-red',
            TaskPickedUp: 'color-icon-blue',
            TaskPickUpUndone: 'color-icon-orange',
            TaskDropped: 'color-icon-orange',
            TaskBlocked: 'color-icon-red',
            TaskFinished: 'color-icon-green',
            TaskCancelled: 'color-icon-red',
            TaskUnblocked: 'color-icon-blue',
            TaskResumed: 'color-icon-blue',
            TaskArchived: 'color-icon-blue',
            TaskPublished: 'color-icon-green',
            TaskPatched: 'color-icon-orange',
            WorkRegistered: 'color-icon-blue',
        };

        this.icon = iconMap[this.entry.type] || 'assignment';
        this.iconClass = classMap[this.entry.type] || 'color-icon-default';
    }

    private determineScore(): void {
        if (this.entry.type === 'AssetScored' || this.entry.type === 'AssetReplaced') {
            const scoreEntry = this.asAssetScoreLogEntry(this.entry);
            this.assetService.getScoreForAssetScore(scoreEntry.score).then((score) => {
                this.assetScore = score;
            });
        }
    }
}
