import { Component, Input } from '@angular/core';

import { ControlBarService } from '../../../../../services/control-bar.service';

@Component({
    selector: 'app-breadcrumb-bar',
    templateUrl: './breadcrumb-bar.component.html',
    styleUrls: ['./breadcrumb-bar.component.scss'],
})
export class BreadcrumbBarComponent {
    @Input() backTitle: string;

    public constructor(public controlBarService: ControlBarService) {}
}
