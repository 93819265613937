import { isString as _isString } from 'lodash';

export function isString(value: unknown): value is string {
    return _isString(value);
}

// Converts all optional properties of an object to nullable.
export type OptionalToNullable<O> = {
    [K in keyof O]-?: undefined extends O[K] ? NonNullable<O[K]> | null : O[K];
};
