<div>
    <div class="title">{{ 'comp.timeframe-display.title' | translate }}</div>
    <div class="labels">
        <span>{{ 'comp.timeframe-display.morning' | translate }}</span>
        <span>{{ 'comp.timeframe-display.afternoon' | translate }}</span>
        <span>{{ 'comp.timeframe-display.evening' | translate }}</span>
    </div>
    <div class="bar-container">
        <div class="bar"></div>
        <!-- Day shift bar -->
        <div class="selected-bar" [style]="position" *ngIf="!timeSpan.isNightShift">
            <div class="start">
                <span *ngIf="timeSpan.startDate">{{ timeSpan.startDate | date : 'HH:mm' }}</span>
            </div>
            <div class="end">
                <span *ngIf="timeSpan.endDate">{{ timeSpan.endDate | date : 'HH:mm' }}</span>
            </div>
        </div>
        <!-- Night shift left bar -->
        <div
            class="selected-bar"
            [style]="{ left: 0, width: position.width, minWidth: 0 }"
            *ngIf="timeSpan.isNightShift"
        >
            <div class="end">
                <span *ngIf="timeSpan.endDate">{{ timeSpan.endDate | date : 'HH:mm' }}</span>
            </div>
        </div>
        <!-- Night shift right bar -->
        <div
            class="selected-bar"
            [style]="{ left: position.left, width: 100 - timeSpan.left + '%', minWidth: 0 }"
            *ngIf="timeSpan.isNightShift"
        >
            <div class="start">
                <span *ngIf="timeSpan.startDate">{{ timeSpan.startDate | date : 'HH:mm' }}</span>
            </div>
        </div>
    </div>
</div>
