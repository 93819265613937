<app-entity-list-item
    [entity]="issue"
    [title]="title || (issue | entityName : 'ISSUE' | async)"
    [subtitle]="subtitle || (issue | entityLocationDescription | async)"
    [expanded]="expanded"
    [clickable]="clickable"
    [selected]="selected"
    *ngIf="issue"
    #listItem
>
    <app-marker type="ISSUE_TYPE" [data]="issueType"></app-marker>

    <!--    SIMPLE VIEW-->
    <div class="expanded-content" *ngIf="listItem.expanded && simpleView && showActions" @fade>
        <app-simple-entity-actions
            [actions]="actions"
            [disableOnSocketDisconnect]="disableOnSocketDisconnect"
        ></app-simple-entity-actions>
    </div>
    <button
        class="btn btn-small btn-round btn-flat btn-details-simple entity-action"
        *ngIf="simpleView && (showDetails$ | async)"
        (click)="goToDetails($event)"
    >
        <span>i</span>
    </button>
    <!--    ADVANCED VIEW-->
    <div class="expanded-content" *ngIf="listItem.expanded && !simpleView && showActions" @fade>
        <app-advanced-entity-actions
            [actions]="actions"
            [disableOnSocketDisconnect]="disableOnSocketDisconnect"
            [collapseAfter]="advancedEntityActionsCollapseAfter$ | async"
            [showOverflow]="showOverflowActions"
        ></app-advanced-entity-actions>
    </div>
    <app-entity-status
        class="entity-action"
        entityType="ISSUE"
        [status]="issue.status"
        *ngIf="!simpleView"
    ></app-entity-status>
    <div class="btn-row expanded-content" *ngIf="!simpleView">
        <button class="btn btn-small" (click)="goToDetails($event)" *ngIf="showDetails$ | async">
            <span>{{ 'comp.task-list-item.action.details' | translate }}</span>
            <i class="material-icons">keyboard_arrow_right</i>
        </button>
    </div>

    <!-- Issue progress -->
    <div class="entity-action" *ngIf="issue.taskStatuses && issue.status !== 'CLOSED' && issue.status !== 'CANCELLED'" @fade>
        <app-issue-progress [taskStatuses]="issue.taskStatuses"></app-issue-progress>
    </div>
</app-entity-list-item>
