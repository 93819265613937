import { EventEmitter, Injectable } from '@angular/core';

import {
    ConfirmDialogAction,
    DeleteDialogAction,
    InfoDialogAction,
    PromptDialogAction,
    PromptDialogActionConfig,
} from '../../models/simple-dialog.model';
import { ConfirmInfoPopupComponent } from '../components/modals/confirm-info-popup/confirm-info-popup.component';
import { DomService } from './dom.service';

@Injectable({
    providedIn: 'root',
})
export class PopupService {
    public constructor(private readonly domService: DomService) {}

    public showInfo(message: string, title?: string): EventEmitter<boolean | null> {
        return this.showDialog({ action: 'info', message, title });
    }

    public showConfirm(
        message: string,
        title?: string,
        confirmText?: string,
        cancelText?: string
    ): EventEmitter<boolean | null> {
        return this.showDialog({ action: 'confirm', message, title, confirmText, cancelText });
    }

    public showDelete(message: string, title?: string): EventEmitter<boolean | null> {
        return this.showDialog({ action: 'delete', message, title });
    }

    public showPrompt(
        message: string,
        title?: string,
        config?: Partial<PromptDialogActionConfig>
    ): EventEmitter<string | null> {
        return this.showDialog<string>({ action: 'prompt', message, title, config: config });
    }

    private showDialog<TResult extends string | boolean>(
        options: InfoDialogAction | ConfirmDialogAction | PromptDialogAction | DeleteDialogAction
    ): EventEmitter<TResult> {
        return this.domService.appendComponentTo<ConfirmInfoPopupComponent<TResult>>(
            'overlay-container',
            ConfirmInfoPopupComponent,
            { options }
        ).component.onClose;
    }
}
