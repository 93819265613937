import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { isEqual, isNumber } from 'lodash';
import { Observable, isObservable, of } from 'rxjs';
import { fade } from 'shared';

import { DomComponent, DomService } from '../../../services/dom.service';
import { ActionsPopupComponent } from '../../modals/actions-popup/actions-popup.component';
import { IBulkAction } from './interfaces/bulk-action.interface';

@Component({
    selector: 'app-bulk-action-bar',
    templateUrl: './bulk-action-bar.component.html',
    styleUrls: ['./bulk-action-bar.component.scss'],
    animations: [fade()],
})
export class BulkActionBarComponent implements OnChanges, OnDestroy {
    public progress: number;

    @Input() public selectionCount = 0;

    @Input() public actions: Array<IBulkAction> = [];
    protected actionsPrimary: Array<IBulkAction> = [];

    protected actionsSecondary: Array<IBulkAction> = [];

    protected actionsMore: Array<IBulkAction> = [];

    protected popupComponent: DomComponent<ActionsPopupComponent>;

    protected isNumber = isNumber;

    public constructor(private readonly domService: DomService) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (!isEqual(changes['actions']?.previousValue, changes['actions']?.currentValue)) {
            this.buildActions();
        }
    }

    public ngOnDestroy(): void {
        this.popupComponent?.remove();
    }

    public async bulkProcess<E extends Record<K, unknown>, K extends PropertyKey = 'id'>(
        data: Array<E>,
        selectedIds: Array<string>,
        updateEntity: (entity: E) => Promise<void>,
        idProp: K = 'id' as K
    ): Promise<void> {
        this.progress = 0;
        try {
            const entities = data.filter((entity) => {
                return selectedIds.includes(entity[idProp] as string);
            });
            for (let i = 0; i < entities.length; i++) {
                await updateEntity(entities[i]);
                this.progress = (i + 1) / entities.length;
            }
        } finally {
            setTimeout((): void => (this.progress = null), 500);
        }
    }

    protected isDisabled(action: IBulkAction): Observable<boolean> {
        // If there's no selection and the selection isn't ignored, of an action is in progress, the action is disabled.
        if ((!action.ignoreSelection && !this.selectionCount) || isNumber(this.progress)) return of(true);

        // If the action doesn't have an disabled check, it's not disabled.
        if (!action.isDisabled) return of(false);

        return isObservable(action.isDisabled) ? action.isDisabled : of(action.isDisabled());
    }

    protected openMoreActionsMenu($event: MouseEvent): void {
        $event.stopImmediatePropagation();

        // Make sure the popup appears below the button
        const moreButton = $event.target as HTMLElement;
        const { y, height } = moreButton.getBoundingClientRect();

        if (this.popupComponent?.isRemoved === false) {
            this.popupComponent?.remove();
            return;
        }
        this.popupComponent = this.domService.appendComponentTo<ActionsPopupComponent>(
            'overlay-container',
            ActionsPopupComponent,
            {
                origin: [$event.clientX, y + height],
                actions: this.actionsMore,
                isDisabled: this.isDisabled.bind(this),
                selectionCount: this.selectionCount,
            }
        );
        this.popupComponent.on('remove', () => (this.popupComponent = null));
        const component = this.popupComponent.component;
        component.popupResult.subscribe(() => this.popupComponent?.remove());
    }

    private buildActions(): void {
        // Future improvement to base this off of screen/component width
        const MAX_PRIMARY_ACTIONS = 3;
        const MAX_SECONDARY_ACTIONS = 3;
        // Sort actions
        const primaryActions = this.actions.filter((action) => action.actionType === 'PRIMARY');
        const secondaryActions = this.actions.filter((action) => action.actionType === 'SECONDARY');
        this.actionsPrimary = primaryActions.splice(0, MAX_PRIMARY_ACTIONS);
        this.actionsMore = primaryActions;
        this.actionsSecondary = secondaryActions.splice(0, MAX_SECONDARY_ACTIONS);
        this.actionsMore.push(...secondaryActions);
    }
}
